module.exports = [{
      plugin: require('../node_modules/@devsisters/gatsby-plugin-autotrack/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@devsisters/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-83665883-4"},
    },{
      plugin: require('../node_modules/@devsisters/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://153717f70cb145d7b477dd1d371d8d36@sentry.io/1398074"},
    },{
      plugin: require('../node_modules/@devsisters/gatsby-plugin-i18n/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
