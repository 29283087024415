import * as React from 'react';

import Root from './src/components/Root';

interface Props {
  element: React.ReactNode;
  props: any;
}
const wrapRootElement: React.FC<Props> = ({ props, element }) => (
  <Root {...props}>{element}</Root>
);

export default wrapRootElement;
