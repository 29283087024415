import * as React from 'react';
import staticUrlContext from '../contexts/staticUrl';

function useStaticAsset(
  staticUrl: string = React.useContext(staticUrlContext)
) {
  const staticUrlResolver = (
    literals: TemplateStringsArray,
    ...placeholders: any[]
  ) => `${staticUrl}/${String.raw(literals, ...placeholders)}`;
  return React.useCallback(staticUrlResolver, [staticUrl]);
}

export default useStaticAsset;
