// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-coupon-index-page-tsx": () => import("./../../../src/@pages/coupon/index.page.tsx" /* webpackChunkName: "component---src-pages-coupon-index-page-tsx" */),
  "component---src-pages-index-page-tsx": () => import("./../../../src/@pages/index.page.tsx" /* webpackChunkName: "component---src-pages-index-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invite-index-page-tsx": () => import("./../../../src/@pages/invite/index.page.tsx" /* webpackChunkName: "component---src-pages-invite-index-page-tsx" */),
  "component---src-pages-parental-guide-page-tsx": () => import("./../../../src/@pages/parental-guide.page.tsx" /* webpackChunkName: "component---src-pages-parental-guide-page-tsx" */),
  "component---src-pages-parental-guide-tsx": () => import("./../../../src/pages/parental-guide.tsx" /* webpackChunkName: "component---src-pages-parental-guide-tsx" */),
  "component---src-pages-presskit-index-tsx": () => import("./../../../src/pages/presskit/index.tsx" /* webpackChunkName: "component---src-pages-presskit-index-tsx" */),
  "component---src-pages-privacy-policy-page-tsx": () => import("./../../../src/@pages/privacy-policy.page.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-page-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-service-page-tsx": () => import("./../../../src/@pages/terms-of-service.page.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-page-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-zh-hans-coupon-tsx": () => import("./../../../src/pages/zh-Hans/coupon.tsx" /* webpackChunkName: "component---src-pages-zh-hans-coupon-tsx" */),
  "component---src-pages-zh-hans-index-tsx": () => import("./../../../src/pages/zh-Hans/index.tsx" /* webpackChunkName: "component---src-pages-zh-hans-index-tsx" */),
  "component---src-pages-zh-hans-invite-tsx": () => import("./../../../src/pages/zh-Hans/invite.tsx" /* webpackChunkName: "component---src-pages-zh-hans-invite-tsx" */),
  "component---src-pages-zh-hans-parental-guide-tsx": () => import("./../../../src/pages/zh-Hans/parental-guide.tsx" /* webpackChunkName: "component---src-pages-zh-hans-parental-guide-tsx" */),
  "component---src-pages-zh-hans-privacy-policy-tsx": () => import("./../../../src/pages/zh-Hans/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-zh-hans-privacy-policy-tsx" */),
  "component---src-pages-zh-hans-terms-of-service-tsx": () => import("./../../../src/pages/zh-Hans/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-zh-hans-terms-of-service-tsx" */),
  "component---src-pages-zh-tw-coupon-tsx": () => import("./../../../src/pages/zh-TW/coupon.tsx" /* webpackChunkName: "component---src-pages-zh-tw-coupon-tsx" */),
  "component---src-pages-zh-tw-index-tsx": () => import("./../../../src/pages/zh-TW/index.tsx" /* webpackChunkName: "component---src-pages-zh-tw-index-tsx" */),
  "component---src-pages-zh-tw-invite-tsx": () => import("./../../../src/pages/zh-TW/invite.tsx" /* webpackChunkName: "component---src-pages-zh-tw-invite-tsx" */),
  "component---src-pages-zh-tw-parental-guide-tsx": () => import("./../../../src/pages/zh-TW/parental-guide.tsx" /* webpackChunkName: "component---src-pages-zh-tw-parental-guide-tsx" */),
  "component---src-pages-zh-tw-privacy-policy-tsx": () => import("./../../../src/pages/zh-TW/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-zh-tw-privacy-policy-tsx" */),
  "component---src-pages-zh-tw-terms-of-service-tsx": () => import("./../../../src/pages/zh-TW/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-zh-tw-terms-of-service-tsx" */)
}

