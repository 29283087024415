import * as React from 'react';

import PageWrapper from './src/components/PageWrapper';

interface Props {
  element: React.ReactNode;
  props: any;
}
const wrapPageElement: React.FC<Props> = ({ props, element }) => (
  <PageWrapper {...props}>{element}</PageWrapper>
);

export default wrapPageElement;
