import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  I18nContext,
  useLocale,
  useTexts,
  i18nContext,
  useLocaleString,
  toLocaleObject,
} from '@devsisters/gatsby-plugin-i18n';
import classNames from 'classnames';

import * as styles from './styles.module.scss';
import usePlatform from '../../hooks/usePlatform';
import localeNamesContext from '../../contexts/localeNames';
import staticUrlContext from '../../contexts/staticUrl';
import useStaticAsset from '../../hooks/useStaticAsset';

const localeNames = {
  en: 'English',
  ko: '한국어',
  ja: '日本語',
  'zh-Hant': '繁體中文',
  th: 'ภาษาไทย',
  de: 'Deutsch',
};

interface Props {
  pageContext: I18nContext;
  location: Location;
}

const PageWrapper: React.FC<Props> = ({
  children,
  pageContext: { locale = toLocaleObject('en'), translations },
}) => {
  const isMobile = usePlatform() === 'mobile';
  const staticUrl = process.env.PUBLIC_URL!;

  return (
    <i18nContext.Provider value={{ locale, translations }}>
      <staticUrlContext.Provider value={staticUrl}>
        <localeNamesContext.Provider value={localeNames}>
          <PageMeta />
          <div className={classNames(styles.pageWrapper, isMobile && 'mobile')}>
            {children}
          </div>
        </localeNamesContext.Provider>
      </staticUrlContext.Provider>
    </i18nContext.Provider>
  );
};

const PageMeta: React.FC = () => {
  const locale = useLocaleString();
  const t = useTexts(useLocale());
  const asset = useStaticAsset();
  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      <title>{t`title`}</title>
      <meta name="description" content={t`description`} />
      <meta property="og:url" content="www.cookierun.com" />
      <meta
        property="og:image"
        content={asset`homepage/opengraph/${locale}.jpg`}
      />
      <meta property="og:site_name" content={t`title`} />
      <meta property="og:description" content={t`description`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="612" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://www.cookierun.com" />
      <meta name="twitter:title" content={t`title`} />
      <meta name="twitter:description" content={t`description`} />
      <meta
        name="twitter:image"
        content={asset`homepage/opengraph/${locale}.jpg`}
      />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={asset`favicons/apple-touch-icon-57x57.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={asset`favicons/apple-touch-icon-60x60.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={asset`favicons/apple-touch-icon-72x72.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={asset`favicons/apple-touch-icon-76x76.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={asset`favicons/apple-touch-icon-114x114.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={asset`favicons/apple-touch-icon-120x120.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={asset`favicons/apple-touch-icon-144x144.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={asset`favicons/apple-touch-icon-152x152.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={asset`favicons/apple-touch-icon-180x180.png`}
      />

      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={asset`favicons/favicon-16x16.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={asset`favicons/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href={asset`favicons/android-chrome-96x96.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={asset`favicons/android-chrome-192x192.png`}
      />
      <link
        rel="mask-icon"
        color="#FFFFFF"
        href={asset`favicons/safari-pinned-tab.svg`}
      />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta
        name="msapplication-TileImage"
        content={asset`favicons/mstile-144x144.png`}
      />
      <meta name="theme-color" content="#0b0102" />
      <meta name="msapplication-navbutton-color" content="#0b0102" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
    </Helmet>
  );
};

export default PageWrapper;
