import * as React from 'react';
import * as isMobile from 'ismobilejs';

import 'normalize.css';

import {
  DevicePlatform,
  deviceContext,
  DeviceContext,
} from '../contexts/device';

const Root: React.FC = ({ children }) => {
  const [platform, setPlatform] = React.useState<DevicePlatform>('mobile');
  React.useEffect(() => {
    setPlatform(isMobile.any ? 'mobile' : 'desktop');
  }, []);
  const deviceContextValue = React.useMemo<DeviceContext>(
    () => ({ platform }),
    [platform]
  );

  return (
    <deviceContext.Provider value={deviceContextValue}>
      {children}
    </deviceContext.Provider>
  );
};

export default Root;
